<template>
  <div class="dejia-student">
    <login-container :title="title" :action="action"></login-container>
  </div>
</template>

<script setup>
import LoginContainer from './login-container.vue'

const title = '德佳教育 - 学生端'
const action = 'https://stu.tzspace.cn/login/authenticate'
</script>

<style>
.dejia-student {
  height: 100%;
  background-image: url('~@/assets/images/schools/dejia/bg-map.jpeg');
  background-size: 100% 100%;
}
</style>
